exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-program-contentful-program-event-slug-tsx": () => import("./../../../src/pages/en/program/{contentfulProgramEvent.slug}.tsx" /* webpackChunkName: "component---src-pages-en-program-contentful-program-event-slug-tsx" */),
  "component---src-pages-en-program-index-tsx": () => import("./../../../src/pages/en/program/index.tsx" /* webpackChunkName: "component---src-pages-en-program-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-program-contentful-program-event-slug-tsx": () => import("./../../../src/pages/program/{contentfulProgramEvent.slug}.tsx" /* webpackChunkName: "component---src-pages-program-contentful-program-event-slug-tsx" */),
  "component---src-pages-program-index-tsx": () => import("./../../../src/pages/program/index.tsx" /* webpackChunkName: "component---src-pages-program-index-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/Gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-page-with-sections-tsx": () => import("./../../../src/templates/PageWithSections.tsx" /* webpackChunkName: "component---src-templates-page-with-sections-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/PostList.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-program-event-tsx": () => import("./../../../src/templates/ProgramEvent.tsx" /* webpackChunkName: "component---src-templates-program-event-tsx" */)
}

