module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["sk","en"],"defaultLanguage":"sk","fallbackLanguage":"sk","redirect":false,"i18nextOptions":{"lng":"sk","fallbackLng":"sk","returnEmptyString":false,"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/:lang/news/:slug","getLanguageFromPath":true,"excludeLanguages":["sk","en"]},{"matchPath":"/:lang/program/:slug","getLanguageFromPath":true,"excludeLanguages":["sk","en"]},{"matchPath":"/:lang/galeria/:slug","getLanguageFromPath":true,"excludeLanguages":["sk","en"]},{"matchPath":"/:lang?/:path","getLanguageFromPath":true,"languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-59243200-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"fb7a778cb37f794b27b83e81ec2dc2a3","pageViews":"all"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
